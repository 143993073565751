<template>
  <div />
</template>

<!-- Page -->

<script>
import { mapActions } from 'vuex'
import { TYPES as AUTH_TYPES } from './store'

export default {
  name: 'logout',
  methods: {
    ...mapActions({
      ...AUTH_TYPES.AUTH
    })
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.logout()
    })
  }
}
</script>
